@import 'colors'
@import 'dimensions'

$inActiveResourceColor = #dc3a3a

.inactiveResource
  > span
    margin-right units(1)

  color $inActiveResourceColor
  font-weight bold
  margin-left units(4)

.featureTourButton
  display flex
  justify-content center
  font-size 1.1rem
  width units(8)
  height units(8)
  overflow hidden

.tabBarExtraEllipsis
  display flex
  padding $primarySpacing ($primarySpacing * 2)
  justify-content center
  font-size 1.1rem
  width units(8)
  height units(8)
  overflow hidden

  +invertible()
    color fg(primary)

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing

.jsonDisplay
  max-width 80vw
  max-height 80vh

.loadingContainer
  text-align center
  vertical-align middle
  font-size units(12)
  margin-top 6 * $primarySpacing
  +invertible()
    color fg(primary)
    background-color bg(primary)
