@import 'colors'
@import 'dimensions'
@import 'fonts'

.Button
  padding $primarySpacing ($primarySpacing * 2)
  border none
  border-radius 2px
  outline none
  cursor pointer
  transition background-color 100ms

  &.primary
    +invertible()
      &.raised
        if inverted
          color fg(primary)
        else
          color fg(primary, inverted)
        background-color bg(highlight)

        &:hover:not(:disabled)
          background-color darken(bg(highlight), 8%)

      &.flat
        color fg(highlight)
        background-color transparent

        &:hover:not(:disabled)
          background-color rgba(bg(primary, inverted), 8%)

  &.secondary
    +invertible()
      &.raised
        color fg(primary)
        background-color bg(secondary)

        &:hover:not(:disabled)
          background-color darken(bg(secondary), 8%)

      &.flat
        color fg(primary)
        background-color transparent

        &:hover:not(:disabled)
          background-color rgba(bg(primary, inverted), 8%)


  &.warn
    +invertible()
      &.raised
        if inverted
          color fg(primary)
        else
          color fg(primary, inverted)
        background-color bg(warning)

        &:hover:not(:disabled)
          background-color darken(bg(warning), 8%)

      &.flat
        color fg(warning)
        background-color transparent

        &:hover:not(:disabled)
          background-color rgba(bg(primary, inverted), 8%)

  &:disabled
    opacity 0.3
    cursor not-allowed
