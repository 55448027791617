@import 'colors'
@import 'dimensions'
@import 'fonts'

$separatorColor = #465152
$activeButtonBorderColor = #5a5a5a

$separatorGradient = linear-gradient(
  to bottom,
  rgba($separatorColor, 0) 0,
  rgba($separatorColor, 100%) 30%,
  rgba($separatorColor, 100%) 70%,
  rgba($separatorColor, 0) 100%
)

.Root
  width 100vw
  height 100vh
  font $primaryFont
  overflow hidden
  +invertible()
    background-color bg(primary)

  > header
    position absolute
    top 0
    width 100%
    height units(14)
    pointer-events none

    > *
      pointer-events auto

  > main
    height 100%
    overflow auto

$headerColor = #1c2021

.header
  background-color $headerColor
  justify-content space-between
  +invertible()
    color fg(primary)

  .navigationContainer
    display flex
    justify-content center
    width units(12)
    margin-right 0

    .logo
      display block
      height units(10)
      cursor pointer

    .arrow
      width units(8)
      height units(8)
      padding 0

.pageScrollPane
  height 'calc(100% - %s)' % units(14)

.progress
  position fixed
  top 0
  width 100%
  height 0.3rem
  margin-top 0.15rem
  z-index 999999

.tooltipContainer
  position relative

  .tooltip
    font-size 0.8rem
    visibility hidden
    position absolute
    border-radius 2px
    text-align center
    +invertible()
      color fg(primary)
      background-color bg(secondary)
    padding $primarySpacing
    z-index 9999999
    top 120%
    left 0
    right 0
    margin auto
    width fit-content
    white-space nowrap

  .tooltip::after
    content ''
    position absolute
    bottom 100%
    left 50%
    margin-left -5px
    border-width 5px
    border-style solid
    +invertible()
      border-color transparent transparent bg(secondary) transparent

  &:hover
    .tooltip
      visibility visible

.iconButton
  display flex
  justify-content center
  font-size 1.1rem
  width units(8)
  height units(8)
  padding $primarySpacing
  position relative

  .tooltip
    font-size 0.8rem
    visibility hidden
    position absolute
    border-radius 2px
    text-align center
    +invertible()
      color fg(primary)
      background-color bg(secondary)
    padding $primarySpacing
    z-index 9999999
    top 120%

  .tooltip::after
    content ''
    position absolute
    bottom 100%
    left 50%
    margin-left -5px
    border-width 5px
    border-style solid
    +invertible()
      border-color transparent transparent bg(secondary) transparent

  &:hover
    .tooltip
      visibility visible

.manageButton
  @extend .iconButton

.notificationsButton
  @extend .iconButton

.helpButton
  @extend .iconButton

.profileButton
  @extend .iconButton
  width units(8)
  height units(8)
  border-radius 50%
  padding 0
  overflow hidden

  img
    width 100%
    height 100%

.queryBuilder
  flex-grow 1
  height units(10)

.navigation
  flex-grow 1

$userSectionColor = #53595a

.user
  flex-grow 0
  padding $primarySpacing ($primarySpacing * 2) $primarySpacing $primarySpacing
  background-color $userSectionColor

.querySaver
  position absolute
  right units(25)
  top units(15)

.solutionContextBuilder
  position absolute
  right units(4)
  top units(15)
  width units(100)

.button
  display flex
  box-sizing border-box
  height units(10)
  align-items center
  padding $primarySpacing
  border 1px solid transparent
  text-decoration none
  border-radius 2px
  cursor pointer
  user-select none
  transition background-color 200ms
  +invertible()
    color fg(primary)

  &:hover
    +invertible()
      background-color tint(bg(primary), 8%)

  &.active
    border 1px solid $activeButtonBorderColor
    +invertible()
      background-color bg(primary)

  &.solutionContext
    height 100%

    .label
      max-width 8rem
      white-space nowrap
      text-overflow ellipsis
      overflow hidden

    .icon
      margin-left ($primarySpacing / 2)

  &.organizations
    height 100%

    .label
      max-width 8rem
      white-space nowrap
      text-overflow ellipsis
      overflow hidden

    .icon
      margin-left ($primarySpacing / 2)

    &:hover
      background-color rgba(white, 13%)
    &:active
      background-color rgba(white, 18%)

.organizationButton
  display flex

  > div
    +invertible()
      color fg(secondary)
    padding-left units(2)

.separator
  border-left 1px dashed
  border-image $separatorGradient 1

.dropdownContent
  width 250px

.content
  &.enter
    opacity 0
    &.enterActive
      opacity 1

  &.exit
    opacity 1
    &.exitActive
      opacity 0

  transition opacity 200ms
  height 100%
