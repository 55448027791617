@import 'colors'
@import 'dimensions'

$hoverColor = #51595a

$item
  padding $primarySpacing
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
  cursor pointer

.tours > li
  @extend $item
  text-transform capitalize

  &:hover
    background-color $hoverColor

.documentation
  @extend $item

  &:hover
    background-color $hoverColor

.featureTours
  font-weight bold
  padding $primarySpacing

.divider
  margin $primarySpacing 0
  border-top 1px solid
  +invertible()
    border-top-color fg(disabled)
