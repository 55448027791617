@import 'dimensions'
@import 'colors'

$notificationBorderColor = #dae1e2
$notificationHeaderColor = #868686

.notificationsList
  max-height 80vh
  max-width 80vw
  overflow scroll

  li
    display flex

    label
      font-weight bold
      flex 0 0 120px
      margin-bottom 10px

  pre
    border 1px solid $notificationBorderColor
    border-radius 3px
    padding 9.5px
    white-space pre-wrap
    +invertible()
      background-color bg(secondary)

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing
