@import 'dimensions'
@import 'colors'

.JsonDisplay
  padding $primarySpacing
  font-family monospace
  +invertible()
    color fg(secondary)
    background-color bg(secondary)
  overflow auto

.clickable
  cursor pointer
