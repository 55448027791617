@import 'dimensions'
@import 'colors'

:global(.notif-transition-enter)
  opacity 0.01
  transition opacity 0.5s ease-in

:global(.notif-transition-enter-active)
  opacity 1

:global(.notif-transition-leave)
  opacity 1
  transition opacity 0.5s ease-in

:global(.notif-transition-leave-active)
  opacity 0.01

.Notifications
  position absolute
  top 70px
  right 40px
  z-index 2000
  width 350px

  .container
    position absolute
    top 0
    width 350px

    .popup
      margin-top $primarySpacing * 0.5
      display flex
      flex-direction row

      .message
        flex-grow 1
        padding units(3)
        color black
        border-radius 0 3px 3px 0

      .icon
        padding-top units(1)
        border-radius 3px 0 0 3px
        text-align center

        .fontIcon
          margin 10px
          font-size 15px

        .info
          +invertible()
            background-color bg(highlight)

        .error
          +invertible()
            background-color bg(warning)

        .success
          +invertible()
            background-color bg(success)
