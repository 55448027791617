@import 'dimensions'
@import 'colors'

$orange = #efa30b
$jsonMaxHeight = units(30)

.applicablePolicies
  display grid
  grid-template-areas 'policyList policyTitle invocationTitle' 'policyList policyDoc invocationStatus' 'policyList actions actions'
  grid-template-columns auto 35% 35%
  grid-column-gap 2 * $primarySpacing
  grid-row-gap $primarySpacing

.policyList
  grid-area policyList

.policyTitle
  font-weight bold
  grid-area policyTitle

.policyDisplay
  grid-area policyDoc

.policyDisplayInfo
  margin $primarySpacing

  .info
    margin-right $primarySpacing
    +invertible()
      color bg(info)

.invocationTitle
  font-weight bold
  grid-area invocationTitle

.invocationStatus
  grid-area invocationStatus
  display grid
  grid-template-areas 'invocationLogo invocationTime' 'invocationError invocationError'
  grid-template-columns auto 1fr
  grid-template-rows auto 1fr
  grid-column-gap $primarySpacing
  grid-row-gap $primarySpacing
  max-height $jsonMaxHeight

.invocationLogo
  grid-area invocationLogo

.invocationTime
  grid-area invocationTime

.invocationError
  grid-area invocationError
  overflow hidden
  text-overflow ellipsis
  -webkit-line-clamp 2
  -webkit-box-orient vertical

.applicablePolicyStatus
  padding units(2)

.invoke
  grid-area actions

.jsonDisplay
  max-height $jsonMaxHeight
  overflow hidden

.invokeButton
  float right
  margin-left $primarySpacing

.error
  +invertible()
    color fg(warning)

.warning
  color $orange

.success
  +invertible()
    color bg(success)

.spinner
  animation spin 1.2s linear infinite

@keyframes spin
  100%
    transform rotate(360deg)
