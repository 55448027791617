@import 'dimensions'
@import 'colors'

.CardHeader
  border-bottom-style solid
  border-radius 2px 2px 0 0
  +invertible()
    color fg(primary)
  background-color $headerBackgroundColor
  display flex

.title
  flex-grow 1
  padding $primarySpacing (2 * $primarySpacing)

.actionButton
  display flex
  justify-content center
  font-size 1.1rem
  width units(8)
  overflow hidden
