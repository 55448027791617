use('invertible.js')

__noop()
  {block}

__normal()
  if !match('::[a-z]+$', selector())
    &:global(.normal)
      {block}
  ^[0]:global(.normal) ^[1..-1]
  :global(.normal) &
  :global(.inverted) :global(.normal) &
  :global(.normal) :global(.inverted) :global(.normal) &
  :global(.inverted) :global(.normal) :global(.inverted) :global(.normal) &
  &
    {block}

__inverted()
  if !match('::[a-z]+$', selector())
    &:global(.inverted)
      {block}
  ^[0]:global(.inverted) ^[1..-1]
  :global(.inverted) &
  :global(.normal) :global(.inverted) &
  :global(.inverted) :global(.normal) :global(.inverted) &
  :global(.normal) :global(.inverted) :global(.normal) :global(.inverted) &
    {block}

__color(category, type, name)
  return $colors[category][type][name]
