@import 'dimensions'
@import 'colors'

$inactiveColor = #cccccc

.active
  +invertible()
    color fg(highlight)

.inactive
  color $inactiveColor

.checkboxContainer
  display flex

.checkbox
  margin-right $primarySpacing
  cursor pointer

.formField
  input
    width units(200)

    &:disabled
      background repeating-linear-gradient(
        135deg,
        #fff,
        #fff $primarySpacing,
        #fafafa $primarySpacing,
        #fafafa ($primarySpacing * 2)
      )

.contents
  max-height 80vh
  overflow auto

  select
    margin-bottom $primarySpacing * 2

.description
  width units(200)
  margin-bottom $primarySpacing * 3

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing

.copy
  margin $primarySpacing 0

.hint
  margin-left $primarySpacing
