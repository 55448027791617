@import 'colors'
@import 'dimensions'

.pagination
  display flex
  justify-content center
  list-style none
  margin units(4)

  li
    padding units(1) units(2)
    border-radius 2px
    margin 0 units(1)
    text-decoration none
    font-weight bold
    cursor pointer
    +invertible()
      color fg(secondary)
      background-color bg(secondary)

    &:hover
      +invertible()
        color fg(primary)

    &.active
      cursor default
      +invertible()
        color fg(primary)
        background-color bg(highlight)

    &:first-child
      margin-left 0

    &:last-child
      margin-right 0
