@import 'dimensions'
@import 'colors'

.Relationships
  table-layout fixed

.relatedResourceHeader
  text-align left

.relatedResource
  text-align left
  cursor pointer
  +invertible()
    color fg(highlight)

  &:hover
    +invertible()
      background-color bg(secondary)

.currentResource
  text-align right

.arrow
  text-align center
  width 20%

.noRelationships
  font-style italic

.resourceType
  +invertible()
    color fg(secondary)
