@import 'dimensions'
@import 'colors'

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing

.help
  margin 0 0 units(2) units(1)

.copy
  display inline
  padding 0
  width units(6)
  height units(6)
