@import 'colors'
@import 'dimensions'

.RadioButton
  border-radius 2px
  overflow hidden

  .Item
    display inline-block
    padding $primarySpacing
    cursor pointer
    +invertible()
      color fg(secondary)

    &.active
      +invertible()
        color fg(primary)

      &.filled
        +invertible()
          background-color bg(highlight)

      &.empty
        +invertible()
          border 2px solid fg(highlight)

    &.disabled
      cursor not-allowed

    &.empty
      +invertible()
        border 2px solid bg(secondary)

    &.filled
      +invertible()
        background-color bg(secondary)
