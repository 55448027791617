@keyframes LoadingIndicator
  0%
    transform rotate(0deg)
  100%
    transform rotate(360deg)

.LoadingIndicator
  $edgeDiameter = 1em
  $edgeWidth    = 0.1 * $edgeDiameter

  $coverOverlap  = 0.1 * $edgeDiameter // A small overlap improves the appearance of edges
  $coverDiameter = $edgeDiameter + 2 * $coverOverlap
  $coverRadius   = $coverDiameter / 2

  display inline-block
  text-indent -99999em
  position relative
  width $edgeDiameter
  height $edgeDiameter
  background-color inherit
  border-radius 50%
  box-shadow inset 0 0 0 $edgeWidth
  transform translateZ(0)

  &::before,
  &::after
    content ''
    position absolute
    top -($coverOverlap)
    width $coverRadius
    height $coverDiameter
    background-color inherit

  &::before
    border-radius $coverRadius 0 0 $coverRadius
    left -($coverOverlap)
    animation LoadingIndicator 1.5s infinite ease 1s
    transform-origin $coverRadius $coverRadius

  &::after
    border-radius 0 $coverRadius $coverRadius 0
    left $coverRadius
    animation LoadingIndicator 1.5s infinite ease
    transform-origin 0 $coverRadius
