@import 'fonts'
@import 'dimensions'
@import 'colors'

.page
  width 100vw
  height 100vh
  font $primaryFont
  overflow hidden
  +invertible()
    background-color bg(primary, inverted)

.selectOrganization
  text-align center
  font $primaryFont
  position fixed
  top 50%
  left 50%
  transform translate(-50%, -50%)
  width 400px
  border-radius 5px
  padding $primarySpacing
  +invertible()
    background-color bg(primary)

  > p
    padding $primarySpacing

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing
