@import 'dimensions'
@import 'colors'

$inactiveColor = #cccccc

.contents
  max-height 80vh
  overflow auto

  select
    margin-bottom $primarySpacing * 2

.description
  width units(200)
  margin-bottom $primarySpacing * 3

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing

.hint
  margin-left $primarySpacing
