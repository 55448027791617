@import 'dimensions'
@import 'colors'

.toggle
  font-weight bold
  cursor pointer
  user-select none
  margin-right $primarySpacing

  &:hover
    +invertible()
      color fg(secondary)

.resourceGroups
  table
    width 100%
    +invertible()
      color fg(primary)

    tbody
      +invertible()
        color fg(primary)
        background-color bg(primary)

    td, th
      padding $primarySpacing

    th
      border-bottom 1px solid rgba(white, 0.08)
      font-weight bold

    td
      border-bottom 1px solid rgba(white, 0.04)

      &:last-child
        width (10 * $primarySpacing)

        a
          float left
          margin-right (2 * $primarySpacing)

          &:last-child
            margin-right 0

      .edit, .delete
        display flex
        justify-content center
        font-size 1.1rem
        padding 0
        width units(6)
        height units(6)

    .names
      width 25%

    .queries
      width 65%

    tbody tr
      &:hover
        cursor pointer
        +invertible()
          background-color bg(dialog)

      &.active
        +invertible()
          background-color bg(secondary)

      &:last-child
        td
          border-bottom none

.deEmphasized
  color rgba(255, 255, 255, 0.3)
  &::before
    background-color transparent

.compliance > div, .deEmphasized > div
  border-radius 1000px
  content ''
  float left
  height 1rem
  width 1rem
  margin-right 1rem
