$badgeColor = #982c0c

.Badge
  position relative

  .badge
    position absolute
    right 0
    bottom -7px
    padding 0 0.2rem
    color white
    font-size 0.7rem
    text-align center
    background-color $badgeColor
    border-radius 2px
    pointer-events none
