@import 'colors'
@import 'dimensions'
@import 'fonts'

$backgroundColor = #fafafa

.Docs
  background-color $backgroundColor
  height 100%
  width 100%
  padding $primarySpacing

  :global(.swagger-ui) :global(.scheme-container)
    visibility hidden
    height 0
    width 0
    margin 0
    padding 0

  :global(.swagger-ui) :global(.info)
    margin 3 * $primarySpacing 0 3 * $primarySpacing

  :global(.try-out)
    visibility hidden

.loading
  margin-top units(30)
  text-align center
  height 100vh
