@import 'dimensions'
@import 'colors'

.containingResourceGroups
  display flex
  flex-direction row
  flex-wrap nowrap
  justify-content flex-start
  align-items stretch

.leafGroups
  order 1
  flex-grow 1
  flex-shrink 1
  flex-basis auto

.ancestorGroups
  order 2
  flex-grow 1
  flex-shrink 1
  flex-basis auto

.loading
  padding $primarySpacing
  background-color white
  opacity 0.7
