@import 'dimensions'
@import 'colors'

.formField
  input
    width units(200)

.contents
  max-height 80vh
  overflow auto

  select
    margin-bottom $primarySpacing * 2

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing

.hint
  margin-left $primarySpacing

.code
  font $monospaceFont
  font-style italic

.description
  width units(200)
  margin-bottom $primarySpacing * 3

.copy
  margin $primarySpacing 0

.checkboxContainer
  display flex

.checkbox
  margin-right $primarySpacing
  cursor pointer

$inactiveColor = #cccccc

.active
  +invertible()
    color fg(highlight)

.inactive
  color $inactiveColor
