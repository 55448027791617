@import 'dimensions'
@import 'colors'
@import 'fonts'

.formField
  input
    width units(200)

.title
  display flex
  height units(16)
  align-items center
  margin-bottom $primarySpacing * 2

  .logo
    width units(20)
    padding $primarySpacing

  .accountName
    flex-grow 1
    margin-right $primarySpacing * 2

    input
      width 100%

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing

.code
  font $monospaceFont
  font-style italic

.hint
  margin-left $primarySpacing
