@import 'dimensions'
@import 'colors'

.clickableList
  li
    &:not(:first-child)
      padding-top $primarySpacing
    cursor pointer
    border-radius ($primarySpacing / 2)

    :hover
      +invertible()
        background-color bg(secondary)

.selected
  font-weight bold
