@import 'colors'
@import 'fonts'

.ModalDialog .dialogContainer
  display flex
  align-items center
  justify-content center

.dialog
  $primaryFont
  border-radius 2px
