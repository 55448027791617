@import 'dimensions'

$activeColor = green
$inactiveColor = #cccccc

.active
  color $activeColor

.inactive
  color $inactiveColor

.help
  margin 0 0 units(2) units(1)

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing
