@import 'colors'
@import 'fonts'
@import 'dimensions'

$downWidth = 28%
$separatorColor = #465152
$separatorGradient = linear-gradient(
  to bottom,
  rgba($separatorColor, 0) 5%,
  rgba($separatorColor, 100%) 20%,
  rgba($separatorColor, 100%) 80%,
  rgba($separatorColor, 0) 95%
)

.Manage
  display grid
  grid-template-columns 190px 1px auto
  grid-template-rows 'calc(100vh - %s)' % units(14)

.optionPanel
  padding 4 * $primarySpacing 3 * $primarySpacing
  +invertible()
    color fg(primary)

.optionDisplay
  height 100%
  overflow auto
  padding 2 * $primarySpacing 3 * $primarySpacing

.optionList
  li
    &:not(:first-child)
      padding-top 2 * $primarySpacing
    padding-bottom units(1)
    white-space nowrap

.selectedOption
  +invertible()
    color fg(highlight)

.filter
  +invertible()
    color fg(secondary)
    background-color bg(secondary)

  input
    padding $primarySpacing
    font $primaryFont
    border none
    border-radius 0 2px 2px 0
    outline none

.separator
  left (100% - $downWidth)
  top 0
  height 100%
  border-left 1px dashed
  border-image $separatorGradient 1
  opacity 1

.navBarExtra
  border none
  font $primaryFont
  cursor pointer
  padding $primarySpacing (2 * $primarySpacing)
  outline none
  border-radius 0 2px 0 0
  +invertible()
    color fg(primary)
    background-color bg(highlight)
