@import 'dimensions'

.Toolbar
  display flex
  align-items stretch

.ToolbarSection
  display flex
  align-items center
  padding $primarySpacing

  > *
    margin-right $primarySpacing

    &:last-child
      margin-right 0
