@import 'colors'
@import 'dimensions'
@import 'fonts'

$uncommittedSearchColor = $colors.bg.normal.highlight

$cubicEasing = cubic-bezier(0.645, 0.045, 0.355, 1)
$transitionDuration = 700ms
$uncommittedSearchTransitionDuration = 1000ms

$colors.bg.normal.queryBuilder = #c7c7c7
$colors.bg.inverted.queryBuilder = #53595a
$uncommittedSearchColor = $colors.bg.normal.highlight

$cubicEasing = cubic-bezier(0.645, 0.045, 0.355, 1)
$transitionDuration = 700ms
$uncommittedSearchTransitionDuration = 1000ms

$iconButton
  display flex
  justify-content center
  font-size 1.1rem
  padding $primarySpacing
  position relative

  .tooltip
    font-size 0.8rem
    visibility hidden
    position absolute
    border-radius 2px
    text-align center
    +invertible()
      color fg(primary)
      background-color bg(secondary)
    padding $primarySpacing
    z-index 9999999
    top 120%
    white-space nowrap

  .tooltip::after
    content ''
    position absolute
    bottom 100%
    left 50%
    margin-left -5px
    border-width 5px
    border-style solid
    +invertible()
      border-color transparent transparent bg(secondary) transparent

  &:hover
    .tooltip
      visibility visible


.QueryBuilder
  display flex
  align-items stretch
  height 100%

  .clearButton
  .searchButton
    @extend $iconButton
    display flex
    border none
    border-radius inherit
    outline none
    cursor pointer
    transition background-color 100ms
    width units(10)
    height units(10)
    +invertible()
      background-color bg(secondary)
      color fg(secondary)

      &:hover
        background-color bg(secondary)
        color fg(primary)

  .searchButton
    border-radius 0 2px 2px 0
    margin-right $primarySpacing

  .createButton
    @extend $iconButton
    width units(8)
    height units(8)

  &.expanded
    .queryForm
      flex-grow 1

      input
        padding $primarySpacing
        font $primaryFont

      .notSubmitted
        animation-name uncommittedSearch
        animation-duration $uncommittedSearchTransitionDuration
        animation-iteration-count infinite
        +invertible()
          color lighten(fg(highlight), 50%)

    .searchButton
      +invertible()
        color fg(primary)

      &.uncommittedSearch
        animation-name uncommittedSearch
        animation-duration $uncommittedSearchTransitionDuration
        animation-iteration-count infinite
        +invertible()
          color lighten(fg(highlight), 50%)

@keyframes uncommittedSearch
  0%
    text-shadow none
  50%
    text-shadow 0px 0px 24px $uncommittedSearchColor, 0px 0px 16px $uncommittedSearchColor, 0px 0px 8px $uncommittedSearchColor
  100%
    text-shadow none


.context
  padding-right $primarySpacing
  border-top-left-radius 2px
  border-bottom-left-radius 2px
  overflow hidden
  +invertible()
    background-color rgba(bg(queryBuilder), 80%)

.queryForm
  display flex
  align-items center
  transition flex-grow $transitionDuration $cubicEasing

  input
    box-sizing border-box
    display block
    align-self baseline
    width 100%
    height 100%
    padding 0
    color inherit
    font $primaryFont
    font-size 0
    border 0
    outline 0
    cursor text
    transition font-size $transitionDuration $cubicEasing, padding $transitionDuration $cubicEasing
    +invertible()
      background-color rgba(bg(queryBuilder), 80%)
