@import 'colors'
@import 'dimensions'

$hoverColor = #51595a

$item
  padding $primarySpacing
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
  cursor pointer

  &:hover
    background-color $hoverColor


.notifications > li
  @extend $item

.divider
  margin $primarySpacing 0
  border-top 1px solid
  +invertible()
    border-top-color fg(disabled)

.allButton
  @extend $item
  text-align center
