@import 'colors'
@import 'dimensions'

.navigation
  border-radius 2px 2px 0 0
  border-bottom-style solid
  display flex
  background-color $headerBackgroundColor
  white-space nowrap

  ul
    flex-grow 1

.navigationTab
  display inline-block
  padding $primarySpacing (2 * $primarySpacing)
  cursor pointer
  opacity 0.5
  +invertible()
    color fg(primary)

  &.active
    opacity 1
