@import 'colors'
@import 'dimensions'
@import 'fonts'

$hoverColor = #51595a

.organizations
  a
    display flex
    padding $primarySpacing

    &.activeOrganization
      +invertible()
        background-color bg(highlight)

    &:hover
      color white
      cursor pointer
      background-color $hoverColor

  i
    display block
    padding $primarySpacing

.organizationButton
  > div
    +invertible()
      color fg(secondary)
    padding-left units(2)


.organizationList
  max-height 18vh
  overflow auto

.profileHeader
  font-weight bold

.filter
  margin $primarySpacing 0

  input
    width 100%
