@import 'dimensions'
@import 'colors'

.formField
  input
    width units(200)

.contents
  max-height 80vh
  overflow auto

  select
    margin-bottom $primarySpacing * 2

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing

.hint
  margin-left $primarySpacing
