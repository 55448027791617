@import 'colors'
@import 'dimensions'
@import 'fonts'

.Filter
  +invertible()
    background-color bg(primary)

  display flex
  align-items center

  span
    padding $primarySpacing

  input
    padding $primarySpacing
    font $primaryFont
    background-color transparent
    border none
    outline none
    flex-grow 1
    +invertible()
      color fg(primary)
