@import 'dimensions'

.splitField
  width units(200)
  margin-bottom $primarySpacing * 3

.inputContent
  display flex
  margin-bottom $primarySpacing * 2

  input
    flex-grow 1
    margin-bottom 0

    &:disabled
      background repeating-linear-gradient(
        135deg,
        #fff,
        #fff $primarySpacing,
        #fafafa $primarySpacing,
        #fafafa ($primarySpacing * 2)
      )

    &:first-child
      margin-right $primarySpacing

  button
    margin-left $primarySpacing
