@import 'colors'
@import 'dimensions'
@import 'fonts'

$hoverColor = #51595a

.SolutionContextsList
  a
    display block
    padding $primarySpacing

    &.activeSolutionContext
      +invertible()
        background-color bg(highlight)

    &:hover
      color white
      cursor pointer
      background-color $hoverColor

  i
    display block
    padding $primarySpacing

.solutionContextsList
  max-height 18vh
  overflow auto

.solutionContextHeader
  font-weight bold

.filter
  margin $primarySpacing 0

  input
    width 100%

.divider
  margin $primarySpacing 0
  border-top 1px solid
  +invertible()
    border-top-color fg(disabled)
