@import 'colors'
@import 'dimensions'

.viewTitle
  padding-left ($primarySpacing * 3)

.edit, .delete
  display flex
  justify-content center
  font-size 1.1rem
  padding 0
  width units(6)
  height units(6)
