@import 'colors'
@import 'dimensions'
@import 'fonts'

.solutionGallery
  display grid
  grid-template-columns repeat(auto-fill, 33.33%)
  grid-auto-rows minmax(150px, auto)

  @media (max-width 1200px)
    grid-template-columns repeat(auto-fill, minmax(300px, 1fr))

  width 100%

.info
  margin-right $primarySpacing
  +invertible()
    color bg(info)
