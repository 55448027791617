@import 'colors'
@import 'dimensions'

.CardBody
  padding $primarySpacing * 2
  +invertible()
    color fg(primary)
    background-color bg(primary)
  overflow hidden
  border-radius 0 0 2px 2px
