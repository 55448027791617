$resourceGroupButtonPrimary = #365b79
$resourceGroupButtonSecondary = #ccc
$resourceGroupHoverColor = #262d2e
$policyButtonPrimary = rgba(15, 110, 137, 0.75)
$policyButtonSecondary = #999

.tree, .tree ul, .tree li
  position relative
  padding-bottom 5px
  margin-top 15px

.tree
  ul
    list-style none
    padding-left 32px
    & > li
      &:last-child
        &::after
          height 33px

  li
    &::before
      border-top 1px solid grey
      top 18px
      width 20px
      height 0
    &::after
      border-left 1px solid grey
      height 140%
      width 0
      top -15px

  > ul > li
    &:first-child
      &::before
        border none
      &::after
        border none

    label
      display inline-block

.tree li::before, .tree li::after
  content ''
  position absolute
  left -20px

.button
  padding 10px 50px
  text-decoration none

.resourceGroup
  > label:nth-of-type(1)
    @extend .button
    background-color $resourceGroupButtonPrimary
    color white

    &:hover
      background-color $resourceGroupHoverColor
      cursor pointer

  > label:nth-of-type(2)
    @extend .button
    background-color $resourceGroupButtonSecondary
    color black

.policy
  > label
    @extend .button
    background-color $policyButtonPrimary
    color white

  > div label
    @extend .button
    background-color $policyButtonSecondary
    color white

.tooltip
  display inline-flex
