@import 'colors'
@import 'dimensions'
@import 'fonts'

$uncommittedSearchColor = $colors.bg.normal.highlight
$uncommittedSearchTransitionDuration = 1000ms

$colors.bg.normal.search = #c7c7c7
$colors.bg.inverted.search = #53595a
$uncommittedSearchColor = $colors.bg.normal.highlight
$uncommittedSearchTransitionDuration = 1000ms

.Search
  +invertible()
    color fg(primary)
    background-color rgba(bg(search), 80%)

  display flex
  align-items center

  span
    padding $primarySpacing

  input
    border-radius 0 2px 2px 0
    padding $primarySpacing $primarySpacing $primarySpacing 2 * $primarySpacing
    font $primaryFont
    background-color transparent
    border none
    outline none
    margin 0
    flex-grow 1
    +invertible()
      color fg(primary)

  .searchButton
    +invertible()
      color fg(primary)
      background-color transparent
    border none
    cursor pointer
    transition background-color 100ms

    &.uncommittedSearch
      animation-name uncommittedSearch
      animation-duration $uncommittedSearchTransitionDuration
      animation-iteration-count infinite

@keyframes uncommittedSearch
  0%
    text-shadow none
  50%
    text-shadow 0px 0px 24px $uncommittedSearchColor, 0px 0px 16px $uncommittedSearchColor, 0px 0px 8px $uncommittedSearchColor
  100%
    text-shadow none
