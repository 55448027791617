@import '~nib/lib/nib/index.styl'
@import 'colors'
@import 'dimensions'
@import 'fonts'

$borderColor = '#d2d2d2'

global-reset()

*
  box-sizing border-box
  vertical-align top

html
  font-size $baseFontSize

body
  overflow hidden

  .disableTransitions *
    transition-duration 1ms !important

h1
  font-size 1.5rem
  margin-bottom $primarySpacing

h3
  font-size 1.4rem

small
  font-size 0.8rem
  +invertible()
    color fg(secondary)

input
button
  font inherit

$selectionColor = #4ae9ed
::selection
  background-color $selectionColor

table
  width 100%

  td, th
    padding $primarySpacing

  th
    background-color rgba(white, 2%)
    border-bottom solid rgba(white, 8%) 1px
    font-weight bold
    +invertible()
      color fg(secondary)

  td
    border-bottom 1px solid rgba(white, 0.04)

  tbody
    tr
      &:hover
        background-color $hoverColor

      &:last-child
        td
          border-bottom none

form
  input
    padding $primarySpacing
    margin-bottom $primarySpacing * 2
    border 1px solid $borderColor
    outline none

  label
    font-weight bold
    display block
    margin $primarySpacing 0

time
  text-decoration underline
  text-decoration-style dotted

// Auth0 custom styling

$auth0LogoBackground = #262d2e
:global
  body
    .auth0-lock.auth0-lock
      .auth0-lock-header-welcome
        background $auth0LogoBackground
        padding 10px 5px 5px 5px
        border-radius 5px

      .auth0-lock-name
        display none

      .auth0-lock-header
        height auto

      .auth0-lock-header-logo
        height 65px

      .auth0-lock-badge-bottom
        display none

      .auth0-lock-overlay
        background $auth0LogoBackground

      .auth0-lock-widget-container
        font $primaryFont

      &.auth0-lock-opened .auth0-lock-overlay
        opacity 1

// Jira Feedback Button

:global(.atlwdg-trigger.atlwdg-SUBTLE)
  z-index 9999999
