@import 'colors'
@import 'dimensions'

$borderColor = #5b6465

.Dropdown
  z-index 100
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  pointer-events none

.dropdown
  position absolute

$arrow
  width 0
  height 0
  border-left 10px solid transparent
  border-right 10px solid transparent
  border-bottom 10px solid

.arrow
  @extend $arrow
  position relative
  border-bottom-color $borderColor
  pointer-events auto
  z-index 100

  &::before
    @extend $arrow
    content ''
    position relative
    left -10px
    top 1px
    display block

    +invertible()
      border-bottom-color bg(secondary)

.content
  +invertible()
    color fg(primary)
    background-color bg(secondary)

  position relative
  padding $primarySpacing
  border-radius 2px
  overflow hidden
  pointer-events auto
  border 1px solid $borderColor
  box-shadow 0 4px 8px rgba(black, 0.2)
