@import 'colors'
@import 'dimensions'

.notAvailable
  opacity 0.5
  font-style italic

.table
  width 100%
  border-collapse collapse

  th,
  td
    padding 0 units(2) units(1) 0
    vertical-align top

    &:last-child
      padding-right 0

  th
    padding-top 0
    border-bottom units(0.5) solid $accentColor

  td
    padding-top units(1)

.entries
  $topPadding = $primarySpacing * 2
  $nameValueMargin = $primarySpacing * 2
  $nameProportion = 0.3
  $valueProportion = 1 - $nameProportion
  $nestedIndent = $primarySpacing * 4

  display flex
  flex-flow row wrap

  dt,
  dd
    padding-top $topPadding

    &:first-of-type
      padding-top 0

  dt
    flex-basis 'calc(%s% - %s)' % (100 * $nameProportion $nameValueMargin)
    margin-right $nameValueMargin
    font-weight bold

  dd
    flex-basis 70%

  .loading
    opacity 0.5
    background-color white

  .entries
    $parentNameProportion = $nameProportion / $valueProportion
    margin-top 1em
    margin-left 'calc(%s% + %s)' % (-(100 * $parentNameProportion) $nestedIndent)

    dt,
    dd
      &:first-of-type
        padding-top $topPadding

    dt
      $parentNameWidth = '%s * (100% + %s) - %s' % ($nameProportion $nestedIndent $nameValueMargin)
      flex-basis 'calc(%s - %s)' % ($parentNameWidth $nestedIndent)
