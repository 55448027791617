@import 'dimensions'
@import 'colors'

.validationStatus
  width 100%

.formField
  input
    width units(200)

.contents
  max-height 80vh
  overflow auto

.typeSelect
  margin-bottom $primarySpacing * 2

.endpointSelect
  margin-top $primarySpacing

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing

.hint
  margin-left $primarySpacing

.endpointField
  display flex
  margin-bottom $primarySpacing * 2

  input
    flex-grow 1
    margin-bottom 0

  button
    margin-left $primarySpacing

.configurationLabel
  float left
  margin-bottom $primarySpacing

.showSchemaButton
  float right
  margin-bottom $primarySpacing
