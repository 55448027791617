@import 'dimensions'
@import 'colors'

.formField
  input
    width units(200)

    &:disabled
      background repeating-linear-gradient(
        135deg,
        #fff,
        #fff $primarySpacing,
        #fafafa $primarySpacing,
        #fafafa ($primarySpacing * 2)
      )

.hint
  margin-left $primarySpacing

.code
  font $monospaceFont
  font-style italic
