@import 'dimensions'
@import 'colors'
@import 'fonts'

.Hint
  display inline-block

  .text
    padding $primarySpacing
    display none
    max-width 300px
    font-weight normal
    position absolute
    border none
    border-radius 2px
    text-align center
    +invertible()
      color fg(secondary)
      background-color bg(secondary)

  &:hover
    .text
      display block
      z-index 1000
