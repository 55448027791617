@import 'colors'
@import 'dimensions'

.view
  margin-left -($primarySpacing * 2)

.notAvailable
  opacity 0.5
  font-style italic

.edit, .delete
  display flex
  justify-content center
  font-size 1.1rem
  padding 0
  width units(6)
  height units(6)
