@import 'dimensions'

$activeColor = green
$inactiveColor = #cccccc

.info
  padding $primarySpacing
  padding-bottom ($primarySpacing * 3)

.active
  color $activeColor

.inactive
  color $inactiveColor

.edit, .delete
  display flex
  justify-content center
  border units(0.2)

.controls
  text-align right
  margin-top $primarySpacing
