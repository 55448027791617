@import 'colors'
@import 'dimensions'

$compliantResourceColor = #5abaa7
$resourceHoverColor = #e5e8e8

.cardCompact
  border-radius 0

.icon
  margin-left $primarySpacing

.accordion
  width 100%
  outline none
  transition 0.4s
  border-width 0
  border-style inset

  &:after
    content '+'
    float right
    text-shadow none
    font-weight bold
    width 1rem
    text-align center
    margin-left 5px

  &.active
    &:after
      content '-'

.panel
  max-height 0
  padding 0
  overflow auto
  transition 0.3s ease-in-out
  background-color white

  &.show
    max-height 800px

.resourcePanel
  @extend .panel
  &.show
    padding $primarySpacing

.summaryPanel
  @extend .panel

.summaryAccordion
  @extend .accordion
  padding $primarySpacing
  font-size inherit
  text-align left
  border-bottom-width 6px
  vertical-align middle
  cursor pointer
  +invertible()
    color fg(primary)
    background-color bg(primary)

.summary
  > div:nth-child(1),
  > div:nth-child(2)
    width 20%
    float left

    > label
      color white
      text-align center
      padding 10px
      border-radius 25px
      background $cardChangedResourcesColor
      display block
      width 65%
      margin-bottom 20px

  > div:nth-child(2) > label
    background $cardResourcesColor

  > div:nth-child(3)
    width 46%
    float left

    > label
      margin-bottom 27px
      display block
      padding-top 7px
      white-space nowrap
      text-overflow ellipsis
      overflow hidden

.resourceItemsTable
  padding $primarySpacing
  width 100%

  th
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
    padding $primarySpacing
    font-weight 700

  td
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
    max-width 1px
    padding $primarySpacing

  .cardCompact &
    th
      padding 0
    td
      padding-left 0
      padding-right 0

.resourceItemRow
  background-color transparent
  transition background-color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1)

  &.secondary
    +invertible()
      color fg(secondary)

  &.selected
    +invertible()
      color fg(primary)
      background-color bg(highlight)

  &:hover
    background-color $resourceHoverColor
    cursor pointer

.sortableTableHeader
  cursor pointer

.compactableDiv
  width auto
  max-width 100%
  max-height 100%
  overflow hidden
  transition 0.4s
  text-transform none

  +invertible()
    color fg(secondary)

  .cardCompact &
    max-width 0
    max-height 0
