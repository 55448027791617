@import 'colors'
@import 'dimensions'

.ResourcesFilter
  border-radius 2px

  +invertible()
    background-color bg(secondary)

  display flex
  align-items center

  span
    padding $primarySpacing
    color white

  input
    padding $primarySpacing
    font $primaryFont
    background-color transparent
    border none
    outline none
    flex-grow 1
    +invertible()
      color fg(primary)
