@import 'dimensions'
@import 'colors'

.InvocationsList
  max-height 80vh
  max-width 80vw
  overflow auto

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing

.time, .message
  white-space nowrap

.error
  +invertible()
    color fg(warning)

.success
  +invertible()
    color bg(success)

.info
  +invertible()
    color bg(info)

.status
  text-align center

.spinner
  animation spin 2s linear infinite

@keyframes spin
  100%
    transform rotate(360deg)
