@import 'colors'
@import 'dimensions'

$colors.fg.normal.complianceWarning = #c93
$colors.fg.normal.complianceError = #c00
$colors.fg.inverted.complianceWarning = #c93
$colors.fg.inverted.complianceError = #c00

.group
  cursor pointer

.label
  transition fill-opacity 0.75s
  cursor pointer
  font-size 0.2rem
  dominant-baseline middle

  text
    fill-opacity 0.75

  &.secondary
  &.antiHighlight
    +invertible()
      fill fg(hint)

  &.highlight text
    fill-opacity 1
    +invertible()
      fill mix(fg(primary), fg(highlight))

  &.active
    +invertible()
      fill mix(fg(primary), fg(highlight))

  &.antiHighlight text
    fill-opacity 0.5

  &.secondary.antiHighlight text
    fill-opacity 0.25

.edge
  fill none
  pointer-events none
  transition stroke 0.75s, stroke-opacity 0.75s
  stroke-opacity 0.75
  +invertible()
    stroke fg(primary)

  &.secondary
  &.antiHighlight
    +invertible()
      stroke fg(hint)

  &.highlight
    +invertible()
      stroke mix(fg(primary), fg(highlight))

  &.active
    +invertible()
      stroke fg(highlight)

  &.antiHighlight
    stroke-opacity 0.5

  &.secondary.antiHighlight
    stroke-opacity 0.25

.graphContainer
  height 100%

  svg
    width 100%
    height 100%
    user-select none
    cursor default

.empty
  path
    +invertible()
      fill fg(hint)

  text
    font-size 0.5rem
    +invertible()
      fill fg(hint)

.nonCompliant
  cursor default

  +invertible()
    fill fg(complianceError)

.warning
  +invertible()
    fill fg(complianceWarning)

.controls
  position absolute
  right $primarySpacing
  bottom $primarySpacing
  +invertible()
    color fg(primary)

.relatedMatch
  +invertible()
    fill mix(fg(primary), fg(highlight))

.match
  fill yellow
