@import 'colors'
@import 'dimensions'
@import 'fonts'

.warning
  position absolute
  left 50%
  bottom $primarySpacing
  width 35%
  transform translate(-50%, 0)
  padding $primarySpacing
  color white
  font $primaryFont
  border-radius 2px
  z-index 1000
  +invertible()
    background-color bg(warning)

  a
    color white
