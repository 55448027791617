@import 'dimensions'

.formField
  input
    width units(200)

.hint
  margin-left $primarySpacing

.code
  font $monospaceFont
  font-style italic
