@import 'colors'
@import 'dimensions'

$pendingOrange = #ecac35

.policyValidationStatus
  padding $primarySpacing
  transition bottom 1s, opacity 1s
  align-self center

.policyValidationStatusVisible
  @extend .policyValidationStatus
  height auto
  opacity 1

.policyValidationStatusHidden
  @extend .policyValidationStatus
  opacity 0

.validPolicy, .invalidPolicy, .pendingPolicy
  padding $primarySpacing

  span
    padding-right $primarySpacing

.invalidPolicyDetails
  padding-left $primarySpacing * 4

  pre
    display block
    font-family monospace
    font-size units(3)

.validPolicy
  +invertible()
    background-color bg(success)
  color white

.invalidPolicy
  +invertible()
    background-color bg(warning)
  color white
  padding $primarySpacing

.pendingPolicy
  background-color $pendingOrange
  color white
  padding $primarySpacing
