@import 'dimensions'
@import 'colors'

$readNotificationColor = #efefef

.table
  table
    > tbody > tr:hover
      cursor pointer

.read
  background $readNotificationColor

.unread
  font-weight bold
