@import 'colors'
@import 'dimensions'

.edit, .delete
  display flex
  justify-content center
  font-size 1.1rem
  padding 0
  width units(6)
  height units(6)

.viewManageTitle
  padding-left ($primarySpacing * 3)

.action
  cursor pointer
  text-decoration none
  margin-right units(6)
  +invertible()
    color fg(highlight)
    background-color transparent
