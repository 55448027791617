@import 'colors'
@import 'dimensions'

.policyView
  display flex
  width 70vw
  height 70vh
  flex-direction column
  +invertible()
    color fg(primary)

  > footer
    display flex
    flex-direction row
    justify-content flex-end
    margin-top $primarySpacing

    > *
      margin-left $primarySpacing

.controls
  select
    border none
    border-bottom 1px solid white
    background-color rgba(black, 0)
    margin-left $primarySpacing
    +invertible()
      color fg(primary)

  option
    +invertible()
      color fg(primary)
      background-color bg(dialog)

.main
  flex-grow 1
  display flex
  position relative

.textBoxes
  flex-grow 1
  display flex
  flex-direction row

  > div
    flex-grow 1

    &:not(:last-child)
      padding-right units(1)

    &:not(:first-child)
      padding-left units(1)

.side
  flex-grow 1
  display flex
  flex-direction column

.editor
  position relative

  label
    padding-right $primarySpacing
    position absolute
    z-index 100
    opacity 0.3
    right 0
    top 0
    +invertible()
      color fg(primary, inverted)

.policyInformation, .policySchema
  flex-grow 1
  flex-basis 0
  position relative

  label
    padding-right $primarySpacing
    position absolute
    z-index 100
    opacity 0.3
    right 0
    +invertible()
      color fg(primary, inverted)

[data-inactive=true]
  opacity 0.2

.jsonEditor
  height 100%

.policyDescription
  padding $primarySpacing 0

.policySchema
  margin-bottom $primarySpacing

.showPolicyButton
  float right
  margin-bottom $primarySpacing

.validationStatus
  position absolute
  bottom 0
  width 100%
  z-index 250
