@import 'dimensions'
@import 'colors'

.groupPolicies
  > div:not(:last-child)
    margin-bottom $primarySpacing

  ul
    float left
    width 30%

.policyDisplay
  margin-left 30%


.policyDisplayInfo
  margin $primarySpacing

  .info
    margin-right $primarySpacing
    +invertible()
      color bg(info)

.controls
  margin-top $primarySpacing
  text-align right
  button
    margin-left $primarySpacing

.jsonDisplay
  overflow hidden

.noPolicy
  +invertible()
    color fg(primary)
    background-color bg(secondary)
