.Modal
  display block
  position fixed
  top 0
  bottom 0
  right 0
  left 0
  z-index 1000
  overflow auto

  &.backdrop
    background rgba(black, 0.7)
