@import 'colors'
@import 'dimensions'
@import 'fonts'

$errorLabelColor = #d80707

.QuerySaver
  text-align center

  input
    padding $primarySpacing
    margin 0
    font $primaryFont
    border none
    outline none
    +invertible()
      color fg(primary)
      background-color bg(primary)
