@import 'colors'
@import 'dimensions'

.resourceList
  transition 0.4s

.collection
  transition 0.4s

  &.compact
    padding 0

    > div
      border-radius 0

.empty
  display flex
  justify-content center
  transition 0.4s
  font-size units(8)

  +invertible()
    color fg(hint)
