@import 'dimensions'
@import 'colors'

.wrapper
  position absolute
  overflow-y auto
  color white
  right 3%
  top 3%
  width 15%
  max-height 100% - @top

.topLevelNode
  display grid
  padding $primarySpacing
  grid-template-columns 1rem 1fr 1rem

  span
    cursor pointer

.nestedNode
  display grid
  padding $primarySpacing
  grid-template-columns 1rem 1rem 1fr 1rem

  span
    cursor pointer

.parentNode
  display grid
  padding $primarySpacing
  grid-template-columns 1fr
  text-align right

  span
    cursor pointer

.activeNode
  +invertible()
    color bg(highlight)

.groups
  text-align right

.activeNodeName
  +invertible()
    color bg(highlight)
    font-weight bold

.active
  opacity 1

.inactive
  opacity 0.5

.disabled
  pointer-events none
