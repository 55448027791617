@import 'fonts'
@import 'dimensions'
@import 'colors'

.page
  width 100vw
  height 100vh
  font $primaryFont
  overflow hidden
  display flex
  align-items center
  justify-content center
  +invertible()
    background-color bg(primary, inverted)

.error
  text-align center
  font $primaryFont
  width 400px
  border-radius 5px
  padding $primarySpacing
  +invertible()
    background-color bg(primary)

  > p
    padding $primarySpacing
