@import 'colors'

.ResourceGroupGraph
  height 100%

.graphLegend
  position absolute
  top 3%
  left 3%
  +invertible()
    color fg(primary)

  > .legendAttached,
  > .legendInherited
    padding 0.3rem 0.5rem
    border-radius 0.2rem

  > .legendAttached
    border 1px solid white

  > .legendInherited
    border 1px dashed white

.path
  fill-rule evenodd
  overflow hidden
  cursor pointer

.layout
  height 100%

  svg
    width 100%
    height 100%
    display block
    margin auto

.text
  font-size 0.2rem
  cursor pointer
  pointer-events none

.active
  +invertible()
    fill bg(highlight)

.attachedPolicy
  stroke-width 0.5px
  stroke white

.propagatedPolicy
  stroke-width 0.25px
  stroke white
  stroke-dasharray 1.5px

.disabled
  pointer-events none
