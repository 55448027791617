@import 'dimensions'
@import 'colors'

.clickable
  cursor pointer

.list
  position relative

  ul
    list-style none

.title
  padding-bottom $primarySpacing
  font-weight bold

.item
  padding $primarySpacing 0

.highlightedItem
  padding $primarySpacing 0
  +invertible()
    background-color bg(secondary)
