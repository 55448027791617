@import 'colors'
@import 'fonts'

.UriMetric
  font $primaryFont
  +invertible()
    color fg(button)

  &:hover
    +invertible()
      color fg(highlight)
