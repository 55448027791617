@import 'colors'

.ProgressBar
  width 100%
  +invertible()
    background-color fg(primary)

.inProgress
  animation scaleFade 1s infinite ease-in-out

.atRest
  transform scale(1)
  opacity 0

@keyframes scaleFade
  0%
    transform scale(0)
  100%
    transform scale(1)
    opacity 0
