@import 'colors'
@import 'dimensions'

$activeColor = green
$inactiveColor = #cccccc

.edit, .delete
  display flex
  justify-content center
  font-size 1.1rem
  padding 0
  width units(6)
  height units(6)
