@import 'colors'
@import 'dimensions'

.configurationView
  display flex
  width 40vw
  height 40vh
  flex-direction column
  +invertible()
    color fg(primary)

.main
  flex-grow 1
  display flex
  position relative

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing

.textBoxes
  flex-grow 1
  display flex
  flex-direction row

  > div
    flex-grow 1

    &:not(:last-child)
      padding-right units(1)

    &:not(:first-child)
      padding-left units(1)

.editor
  position relative

  label
    padding-right $primarySpacing
    margin-top 0
    position absolute
    z-index 100
    opacity 0.3
    right 0
    top 0
    +invertible()
      color fg(primary, inverted)

.jsonEditor
  height 100%
