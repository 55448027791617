@import 'dimensions'
@import 'colors'
@import 'fonts'

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing

.inputBox
  width units(120)
  padding $primarySpacing
  font-weight bold
  margin $primarySpacing * 2 0 $primarySpacing
