@import 'colors'
@import 'dimensions'

$refreshDisabledColor = #d5d5d5
$activeColor = green
$inactiveColor = #cccccc

.active
  color $activeColor

.inactive
  color $inactiveColor

.galleryItem

  >div
    height 100%

    +invertible()
      color fg(secondary)

.description
  display -webkit-box
  height units(9)
  margin 0 0 units(2)
  overflow hidden
  text-overflow ellipsis
  -webkit-line-clamp 2
  -webkit-box-orient vertical

.tags
  height units(8)
  list-style none
  margin units(2) 0 units(4)
  white-space nowrap
  overflow hidden

  li
    display inline-block
    margin units(1) units(2) units(1) 0
    padding 2px units(2)
    border-radius units(1)
    +invertible()
      border 1px solid bg(secondary)

.action
  cursor pointer
  text-decoration none
  margin-right units(6)
  +invertible()
    color fg(highlight)
    background-color transparent

.refreshDisabled
  color $refreshDisabledColor

.cardHeader
  display flex
  justify-content space-between

  .version
    opacity 0.5
