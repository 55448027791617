@import 'dimensions'
@import 'colors'

$solutionBorderColor = #dae1e2
$solutionHeaderColor = #868686
$codeBackgroundColor = #e8e8e8
$codeBorderBottomColor = #eaecef
$accordionStyle = #333333

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing

.SolutionViewer
  max-height 80vh
  width 80vw
  overflow scroll
  padding 0

.solutionsList
  li
    display flex

  > li:not(:last-child)
    margin-bottom 4 * $primarySpacing

  label
    font-weight bold
    flex 0 0 200px
    cursor inherit

  ul > li > label
    font-weight normal

  // FontAwesome icons within a label
  label > span[class*='fa']
    margin-right 8px

  pre
    margin units(2) units(0) units(2) 0
    border 1px solid $solutionBorderColor
    border-radius 3px
    padding 10px
    white-space pre-wrap
    +invertible()
      background-color bg(secondary)

  code
    font-family monospace
    background-color $codeBackgroundColor
    padding 3px
    line-height 1.5
    font-size 1.1em

  h1, h2, h3, h4
    font-size 1.6em
    font-weight bold
    margin units(2) units(0) units(4) 0
    border-bottom 1px solid $codeBorderBottomColor

.tags
  height units(8)
  list-style none

  li
    display inline-block
    &:not(:last-child)
      margin-right $primarySpacing
    padding 2px units(2)
    border-radius units(1)
    +invertible()
      border 1px solid bg(secondary)

.solutionDescription
  li
    display inline
  white-space pre-wrap

.summaryPanel
  max-height 0
  padding 0
  overflow auto
  transition 0.3s ease-in-out
  background-color white
  > div
    > li:not(:last-child)
      margin-bottom $primarySpacing
    > li
      > ul
        > li:not(:last-child)
          margin-bottom units(1)

  &.show
    max-height 800px
    margin-top $primarySpacing

.summaryAccordion
  width 100%
  padding 0 0 $primarySpacing
  font-size inherit
  text-align left
  outline none
  transition 0.4s
  border-width 0
  border-style inset
  border-bottom-width 6px
  vertical-align middle
  cursor pointer
  +invertible()
    color fg(primary)
    background-color bg(primary)

  &:after
    content '+'
    float right
    text-shadow none
    font-weight bold
    width 1rem
    text-align center
    margin-left 5px

  &.active
    &:after
      content '-'

.nested
  display grid
  grid-template-areas 'label content'
  grid-template-columns 200px auto
  margin-bottom $primarySpacing

.accordionList
  width 100%
  > div:not(:last-child)
    margin-bottom 2 * $primarySpacing

.policyDisplayTitleInfo
  .info
    margin-right $primarySpacing
    +invertible()
      color bg(info)

.suspension
  > p
    margin-bottom 10px
