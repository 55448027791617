@import 'colors'
@import 'dimensions'

$pendingOrange = #ecac35

.configurationValidationStatus
  transition bottom 1s, opacity 1s
  align-self center

.configurationValidationStatusVisible
  @extend .configurationValidationStatus
  height auto
  opacity 1

.configurationValidationStatusHidden
  @extend .configurationValidationStatus
  display none

.validConfiguration, .invalidConfiguration, .pendingConfiguration
  padding $primarySpacing

  span
    padding-right $primarySpacing

.invalidConfigurationDetails
  padding-left $primarySpacing * 4

  pre
    display block
    font-family monospace
    font-size units(3)

.validConfiguration
  +invertible()
    background-color bg(success)
  color white

.invalidConfiguration
  +invertible()
    background-color bg(warning)
  color white
  padding $primarySpacing

.pendingConfiguration
  background-color $pendingOrange
  color white
  padding $primarySpacing
