@import 'colors'
@import 'dimensions'

$hoverColor = #51595a

$colors.bg.normal.resourceGroup = #bfbfbf
$colors.bg.inverted.resourceGroup = #5f6565
$colors.bg.normal.collapsed = shade(#bfbfbf, 8%)
$colors.bg.inverted.collapsed = shade(#5f6565, 8%)

$hoverColor = #51595a

.QueryContext
  display flex

.separator
  content ''
  display block
  position absolute
  top 0
  height 0
  border-left-width $primarySpacing
  border-left-style solid
  border-top ((units(14) - 2 * $primarySpacing) / 2) solid
  border-bottom ((units(14) - 2 * $primarySpacing) / 2) solid

.section
  display flex
  align-items center
  position relative
  height 100%
  margin-left $primarySpacing * 1.2
  padding-left $primarySpacing
  padding-right $primarySpacing
  user-select none
  transition background-color 200ms, border-color 200ms
  +invertible()
    background-color bg(resourceGroup)
    border-color bg(resourceGroup)

  &:first-child:not(.collapsed)
    margin-left 0
    padding-left $primarySpacing * 1.5

  span
    position relative
    top -0.1rem

  &::after
    @extend .separator
    left 100%
    border-left-color inherit
    border-top-color transparent
    border-bottom-color transparent

  &:not(:first-child)::before
  &.collapsed::before
    @extend .separator
    right 100%
    border-left-color transparent
    border-top-color inherit
    border-bottom-color inherit

  &.collapsed
    +invertible()
      background-color bg(collapsed)
      border-color bg(collapsed)

    &:hover
      +invertible()
        background-color tint(bg(collapsed), 8%)
        border-color tint(bg(collapsed), 8%)

a.section
  cursor pointer

  &:hover
    +invertible()
      background-color tint(bg(resourceGroup), 8%)
      border-color tint(bg(resourceGroup), 8%)

  &.active
    +invertible()
      background-color bg(highlight)
      border-color bg(highlight)

.fullBreadcrumb
  a
    display block
    padding $primarySpacing

    &:hover
      color white
      cursor pointer
      background-color $hoverColor
