@import 'colors'

$transitionDuration = 1s

.fadingOut
  animation fadeout $transitionDuration

.micro
  position absolute
  font-size 13px
  left 50%
  top 50%
  transform translate(-50%, -50%)

  +invertible()
    color fg(primary)

.policyTypeSelector
  position relative

  > select
    appearance none
    -moz-appearance none
    -webkit-appearance none
    border none
    border-radius 0.2rem
    padding 0.5rem
    padding-right 2rem
    +invertible()
      background-color bg(secondary)
      color fg(primary)

  > .icon
    position absolute
    right 0.6rem
    top 0.35rem
    pointer-events none
    +invertible()
      color fg(primary)

@keyframes fadeout
  0%
    opacity 1
  100%
    opacity 0
