@import 'dimensions'
@import 'colors'

.formField
  input
    width units(200)

.contents
  max-height 80vh
  overflow auto

  select
    margin-bottom $primarySpacing * 2

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing

.hint
  margin-left $primarySpacing

.groupField
  display flex
  margin-bottom $primarySpacing * 2

  input
    flex-grow 1
    margin-bottom 0

  button
    margin-left $primarySpacing
