@import 'colors'
@import 'dimensions'

$headerHeight = units(14)
$navigationHeight = 'calc(100vh - %s)' % $headerHeight
$upHeight = units(14)

$downWidth = 28%
$upWidth = 18%

$hoverColor = rgba(white, 4%)
$separatorColor = #465152

$separatorGradient = linear-gradient(
  to bottom,
  rgba($separatorColor, 0) 5%,
  rgba($separatorColor, 100%) 20%,
  rgba($separatorColor, 100%) 80%,
  rgba($separatorColor, 0) 95%
)

$transitionDuration = 700ms
$fadeDuration = 150ms
$preFadeDuration = $transitionDuration - $fadeDuration

$easing = unquote('cubic-bezier(0.455, 0.03, 0.515, 0.955)')

$transition($property, $duration = 700ms, $delay = 0ms)
  return $property + ' ' + $duration + ' ' + $easing + ' ' + $delay

$shapeTransitions($duration = $transitionDuration, $delay = 0ms)
  $outputs = ()

  $properties = left top width height
  for $property in $properties
    push($outputs, $transition($property, $duration, $delay))

  return unquote(join(',', $outputs))

$zIndices = {
  'exitAbove': 30,
  'above': 20,
  'normal': 10,
  'exitBelow': 0
}

$zindex(layer)
  z-index $zIndices[layer]

$resetTransitions()
  &.reset
    transition none

    .elementContent
      transition none

.ResourcesLayout
  position absolute
  width 100%
  overflow hidden

  .main
    width 100%
    height auto

    .navigationContainer
      background-color transparent

      .navigation
        position relative
        width 100%
        height $navigationHeight
        overflow hidden
        transition $shapeTransitions()

        .elementContent
          box-sizing border-box
          position absolute
          left 0
          top 0
          width 100%
          height 100%
          padding $primarySpacing
          text-align left
          user-select none
          pointer-events none
          transition opacity 200ms $easing, $shapeTransitions()

        > div
          position absolute
          transition opacity $fadeDuration $easing 0ms, background-color 200ms, $shapeTransitions()
          text-align center
          overflow hidden
          $resetTransitions()

        .upPrevious
        .up
          top 0
          width $upWidth
          height $upHeight

          .elementContent
            left units(8)
            width units(14)
            height 100%

        .upPrevious
          left -($upWidth)
          $zindex('exitAbove')

        .up
          left 0

          &.hasContent
            cursor pointer

            &:hover
              background-color $hoverColor

        .active
          left 0
          top $upHeight
          width (100% - $downWidth)
          height 'calc(100% - %s)' % $upHeight
          $zindex('normal')

          .elementContent
            user-select text
            pointer-events auto
            overflow auto

        .down
        .downExit
        .downNext
          width $downWidth

          .elementContent
            top units(6)
            width 100%
            height ('calc(100% - %s)' % units(8))

        .down
          left (100% - $downWidth)
          opacity 1
          $zindex('above')

          &.hasContent
            cursor pointer

            &:hover
              background-color $hoverColor

          &.downExitOriginal
            transition opacity $fadeDuration $easing $preFadeDuration, $shapeTransitions()

        .downExit
          left (100% - $downWidth)
          opacity 0
          $zindex('exitBelow')

        .downNext
          left 100%
          $zindex('exitAbove')

        .toolbar
          left 18%
          top 0
          width (100% - $upWidth - $downWidth)
          height $upHeight
          justify-content flex-end

        .separator
          left (100% - $downWidth)
          top 0
          height 100%
          border-left 1px dashed
          border-image $separatorGradient 1
          opacity 1

    .content
      margin units(8) 10%

.upPreviousOriginal
.upOriginal
  .arrow
    position absolute
    height $upHeight
    line-height $upHeight
    left units(2)
    opacity 1
    transition opacity 100ms $easing
    +invertible()
      color fg(secondary)

  .label
    position absolute
    line-height $upHeight
    left ($upHeight + units(10))
    text-align left
    font-size 0.8rem
    opacity 1
    transition opacity 100ms $easing
    +invertible()
      color fg(secondary)

  &:hover
    .arrow
    .label
      +invertible()
        if inverted
          color tint(fg(secondary), 30%)
        else
          color shade(fg(secondary), 30%)

.downPreviousOriginal
.downOriginal
  .arrow
    padding-left units(2)
    +invertible()
      color fg(secondary)

  .label
    position absolute
    padding $primarySpacing
    width 100%
    top units(2)
    text-align center
    font-size 0.8rem
    opacity 1
    transition opacity 100ms $easing
    +invertible()
      color fg(secondary)

  &:hover
    .arrow
    .label
      +invertible()
        if inverted
          color tint(fg(secondary), 30%)
        else
          color shade(fg(secondary), 30%)

.transitioning, .initializing
  .upPreviousOriginal
  .upOriginal
  .downPreviousOriginal
  .downOriginal
    .arrow
    .label
      opacity 0
      transition opacity 100ms $easing

  .main .navigationContainer .navigation
    user-select text
    &.hasContent:hover
      background-color transparent
