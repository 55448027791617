@import 'dimensions'
@import 'colors'
@import 'fonts'

.query
  background-color rgba(black, 0.02)
  border-bottom 1px solid rgba(black, 0.08)
  padding $primarySpacing * 2
  margin-bottom $primarySpacing * 2

  strong
    font-weight bold

  p
    margin-top $primarySpacing

.formField
  input
    width units(150)

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing
