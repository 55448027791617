@import 'dimensions'
@import 'colors'

.formField
  input
    width units(200)

.contents
  max-height 80vh
  overflow auto

  select
    margin-bottom $primarySpacing * 2

.item
  &:not(:last-child)
    margin-right $primarySpacing

.controls
  text-align right
  margin-top $primarySpacing

  > *
    margin-left $primarySpacing

.code
  font $monospaceFont
  font-style italic

.hint
  margin-left $primarySpacing

.logo
  margin 0 auto
  width units(10)
  height units(10)
  display block

.description
  width units(200)
  margin-bottom $primarySpacing * 3

.copy
  margin $primarySpacing 0
