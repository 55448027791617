@import 'colors'
@import 'dimensions'
@import 'fonts'

$hoverColor = #51595a

.profile
  a
    display block
    padding $primarySpacing

    &:hover
      color white
      cursor pointer
      background-color $hoverColor
